var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',{staticClass:"headline"},[_vm._v("Scrape Inventory")]),_c('p',{staticClass:"body-2 mt-1"},[_c('span',{staticClass:"d-block"},[_vm._v("This page lists all the inventory scraping jobs")]),_c('span',{staticClass:"d-block"},[_vm._v("Click start new scrape to start a new batch of inventory properties and listings ")]),_c('span',{staticClass:"d-block"},[_vm._v("The job will then appear in the table below")]),_c('span',{staticClass:"d-block"},[_vm._v("You'll receive a notification when the scraping job is ready to be uploaded ")]),_c('span',{staticClass:"d-block"},[_vm._v("You'll then need to complete the scrape by clicking the button in that table row")])])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{attrs:{"color":"primary","depressed":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){_vm.newScrapeDialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-timer-outline")]),_vm._v(" Start New Scrape")],1),_c('p',{staticClass:"caption mt-4"},[_vm._v(" "+_vm._s(_vm.webScraperAccountInfo.page_credits)+" Page Credits Available ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":"","loading":_vm.loading}},[_c('v-card-text',{staticClass:"title text--primary font-weight-regular"},[_vm._v("Inventory Scraping Jobs")]),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":[
            { text: 'Date Created', value: 'dateCreated' },
            { text: 'Name', value: 'sitemapName' },
            { text: 'Job Completed', value: 'dataUploadedToDB' },
            { text: 'Actions', value: 'actions' } ],"items":_vm.invScrapeJobs,"items-per-page":-1,"dense":"","sort-by":"dataUploadedToDB"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.formatDate(data.item.dateCreated)))]),_c('td',[_vm._v(_vm._s(data.item.sitemapName))]),_c('td',[(data.item.dataUploadedToDB)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-close-circle-outline")])],1),_c('td',[(
                      data.item.scrapeComplete && !data.item.dataUploadedToDB
                    )?_c('v-btn',{attrs:{"color":"success","xSmall":"","depressed":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.onGetJobJSON(data.item)}}},[_vm._v("upload & finish")]):_vm._e(),(!data.item.scrapeComplete)?_c('p',{staticClass:"caption mb-0"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"info"}},[_vm._v("mdi-timer-outline")]),_vm._v(" waiting... ")],1):_vm._e(),(
                      data.item.scrapeComplete && data.item.dataUploadedToDB
                    )?_c('p',{staticClass:"caption mb-0"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"success"}},[_vm._v("mdi-check")]),_vm._v(" completed ")],1):_vm._e()],1)])]]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.newScrapeDialog),callback:function ($$v) {_vm.newScrapeDialog=$$v},expression:"newScrapeDialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-text',{staticClass:"body-1 text--primary pt-2"},[_c('h4',{staticClass:"title mt-4"},[_vm._v("Start a New Scrape?")]),_c('p',{staticClass:"mt-4"},[_vm._v(" Starting a new scrape will update all inventory listings and inventory properties. ")]),_c('p',[_vm._v(" You will receive a notification when the job is complete and the data is ready for upload. "),_c('strong',[_vm._v("You'll then need to return to this page and click the complete scrape button in the table.")])]),_c('p',[_vm._v("This action cannot be undone.")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","text":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){_vm.newScrapeDialog = false}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.onStartNewScrape}},[_vm._v(" Start New Scrape ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }