<template>
  <v-container>
    <!-- pageHeader -->
    <v-row>
      <v-col cols="12" md="8">
        <h1 class="headline">Scrape Inventory</h1>
        <p class="body-2 mt-1">
          <span class="d-block"
            >This page lists all the inventory scraping jobs</span
          >
          <span class="d-block"
            >Click start new scrape to start a new batch of inventory properties
            and listings
          </span>
          <span class="d-block"
            >The job will then appear in the table below</span
          >
          <span class="d-block"
            >You'll receive a notification when the scraping job is ready to be
            uploaded
          </span>
          <span class="d-block"
            >You'll then need to complete the scrape by clicking the button in
            that table row</span
          >
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-right">
        <v-btn
          color="primary"
          depressed
          @click="newScrapeDialog = true"
          :loading="loading"
          :disabled="loading"
        >
          <v-icon left>mdi-timer-outline</v-icon>
          Start New Scrape</v-btn
        >
        <p class="caption mt-4">
          {{ webScraperAccountInfo.page_credits }} Page Credits Available
        </p>
      </v-col>
    </v-row>
    <!-- pageHeader -->

    <!-- scraping jobs table -->
    <v-row>
      <v-col cols="12">
        <v-card outlined :loading="loading">
          <v-card-text class="title text--primary font-weight-regular"
            >Inventory Scraping Jobs</v-card-text
          >
          <v-data-table
            :loading="loading"
            :headers="[
              { text: 'Date Created', value: 'dateCreated' },
              { text: 'Name', value: 'sitemapName' },
              { text: 'Job Completed', value: 'dataUploadedToDB' },
              { text: 'Actions', value: 'actions' },
            ]"
            :items="invScrapeJobs"
            :items-per-page="-1"
            dense
            sort-by="dataUploadedToDB"
          >
            <template v-slot:item="data">
              <template>
                <tr>
                  <td>{{ formatDate(data.item.dateCreated) }}</td>
                  <td>{{ data.item.sitemapName }}</td>
                  <td>
                    <v-icon
                      small
                      color="success"
                      v-if="data.item.dataUploadedToDB"
                      >mdi-check-circle-outline</v-icon
                    >
                    <v-icon small color="error" v-else
                      >mdi-close-circle-outline</v-icon
                    >
                  </td>
                  <td>
                    <v-btn
                      color="success"
                      xSmall
                      depressed
                      :loading="loading"
                      :disabled="loading"
                      @click="onGetJobJSON(data.item)"
                      v-if="
                        data.item.scrapeComplete && !data.item.dataUploadedToDB
                      "
                      >upload &amp; finish</v-btn
                    >

                    <p class="caption mb-0" v-if="!data.item.scrapeComplete">
                      <v-icon small class="mr-1" color="info"
                        >mdi-timer-outline</v-icon
                      >
                      waiting...
                    </p>
                    <p
                      class="caption mb-0"
                      v-if="
                        data.item.scrapeComplete && data.item.dataUploadedToDB
                      "
                    >
                      <v-icon small class="mr-1" color="success"
                        >mdi-check</v-icon
                      >
                      completed
                    </p>
                  </td>
                </tr>
              </template>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- scraping jobs table -->

    <!-- new scrape dialog -->
    <v-dialog v-model="newScrapeDialog" width="600">
      <v-card :loading="loading">
        <v-card-text class="body-1 text--primary pt-2">
          <h4 class="title mt-4">Start a New Scrape?</h4>
          <p class="mt-4">
            Starting a new scrape will update all inventory listings and
            inventory properties.
          </p>
          <p>
            You will receive a notification when the job is complete and the
            data is ready for upload.
            <strong
              >You'll then need to return to this page and click the complete
              scrape button in the table.</strong
            >
          </p>
          <p>This action cannot be undone.</p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            text
            @click="newScrapeDialog = false"
            :loading="loading"
            :disabled="loading"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            :loading="loading"
            :disabled="loading"
            @click="onStartNewScrape"
          >
            Start New Scrape
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- newScrapeDialog -->
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import formatDate from "@/mixins/formatDate";
import { portalsApp } from "@/firebase";
const invScrapeJobs = portalsApp.firestore().collection("invScrapeJobs");
export default {
  mixins: [formatDate],
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.$bind("invScrapeJobs", invScrapeJobs);
    let webScraperAccountInfo = await axios.get(
      "/api/get-web-scraper-account-info"
    );
    this.webScraperAccountInfo = webScraperAccountInfo.data.data.data;
    await this.$store.dispatch("setLoading", false);
  },
  data: () => ({
    newScrapeDialog: false,
    invScrapeJobs: [],
    webScraperAccountInfo: "",
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    async dwProperties(data, scrapeID) {
      // filter blanks and format data
      let formattedData = data.filter((i) => i.resortPropertyId !== "");
      formattedData.forEach((i) => {
        i.airportInfo = i.airportInfo
          .replace(/\s\s+/g, " ")
          .replace(" / ", "/");

        i.amenities = this.trimAll(i.amenities);
        i.checkInInfo = this.trimAll(i.checkInInfo);
        i.destination = this.trimAll(i.destination);

        i.resortAddress = i.resortAddress
          .replace(/\s\s+/g, " ")
          .replace(/&nbsp;/gi, " ");

        i.dateSubmitted = new Date().toISOString().substring(0, 10);

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i["image-src"] = "https://www.dreamvacationweek.com" + i["image-src"];

        i.resortPropertyId = i.resortPropertyId.toUpperCase();

        i.activities = "";
        i.otherInfo = "";
        i.restaurants = "";
        i.restrictions = "";
        i.source = "dreamweeks";
        i.template = "inventoryProperties";
        i.unitInfo = "";

        delete i["web-scraper-order"];
        delete i["web-scraper-start-url"];
      });

      // add each formatted item to firestore
      for (let index = 0; index < formattedData.length; index++) {
        const element = formattedData[index];
        let docRef = portalsApp
          .firestore()
          .collection("inventoryProperties")
          .doc(element.resortPropertyId);
        await docRef.set(element, { merge: true });
      }

      // update scraping job in firestore
      await portalsApp
        .firestore()
        .collection("invScrapeJobs")
        .doc(scrapeID)
        .update({
          dataUploadedToDB: true,
        });

      // get web scraper account info
      let webScraperAccountInfo = await axios.get(
        "/api/get-web-scraper-account-info"
      );
      this.webScraperAccountInfo = webScraperAccountInfo.data.data.data;

      await this.$store.dispatch("setLoading", false);
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "success",
        text: "Inventory now live",
        timeout: 5000,
      });
    },
    async tbProperties(data, scrapeID) {
      // filter blanks and format data
      let formattedData = data.filter((i) => i.resortPropertyId !== "");

      formattedData.forEach((i) => {
        i.activities = this.trimAll(i.activities);
        i.airportInfo = this.trimAll(i.airportInfo);
        i.amenities = this.trimAll(i.amenities);
        i.checkInInfo = this.trimAll(i.checkInInfo);
        i.otherInfo = this.trimAll(i.otherInfo);
        i.restaurants = this.trimAll(i.restaurants);
        i.restrictions = this.trimAll(i.restrictions);
        i.unitInfo = this.trimAll(i.unitInfo);
        i.overview = this.trimAll(i.overview);

        i.resortAddress = i.resortAddress.replace("Address:", "").trim();

        i.destination = i.destination.split("-")[0].toLowerCase().trim();

        i.dateSubmitted = new Date().toISOString().substring(0, 10);

        // i.id =
        //   Math.random().toString(36).substr(2, 9) +
        //   Math.random().toString(36).substr(2, 9) +
        //   Math.random().toString(36).substr(2, 9) +
        //   Math.random().toString(36).substr(2, 9) +
        //   Math.random().toString(36).substr(2, 9);

        i.source = "tripbeat";

        if (i.resortPropertyId == "0193") i.destination = "Lake Tahoe";

        delete i["web-scraper-order"];
        delete i["web-scraper-start-url"];
      });

      // add each formatted item to firestore
      for (let index = 0; index < formattedData.length; index++) {
        const element = formattedData[index];

        let docRef = portalsApp
          .firestore()
          .collection("inventoryProperties")
          .doc(element.resortPropertyId);
        await docRef.set(element, { merge: true });
      }

      // update scraping job in firestore
      await portalsApp
        .firestore()
        .collection("invScrapeJobs")
        .doc(scrapeID)
        .update({
          dataUploadedToDB: true,
        });

      // get web scraper account info
      let webScraperAccountInfo = await axios.get(
        "/api/get-web-scraper-account-info"
      );
      this.webScraperAccountInfo = webScraperAccountInfo.data.data.data;

      await this.$store.dispatch("setLoading", false);
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "success",
        text: "Inventory now live",
        timeout: 5000,
      });
    },
    async onGetJobJSON(item) {
      let scrapeID = item.id.toString();
      await this.$store.dispatch("setLoading", true);
      let request = await axios.post("/api/get-scraping-job-json", {
        id: item.id,
      });
      let data = request.data.data;
      // splitting at new lines
      // removing blank items
      // mapping items to JSON
      data = data
        .split("\n")
        .filter((i) => i !== "")
        .map((i) => JSON.parse(i));

      if (item.sitemapName == "dwproperties") this.dwProperties(data, scrapeID);
      if (item.sitemapName == "tbproperties") this.tbProperties(data, scrapeID);
      if (item.sitemapName == "tblistings") this.tblistings(data, scrapeID);
    },
    async tblistings(data, scrapeID) {
      // filter and remove blanks
      let formattedData = data.filter(
        (i) =>
          i.bedrooms !== "" &&
          i.destination !== "" &&
          i.nightlyRate !== "" &&
          i.checkInMonth !== "" &&
          i.checkInDay !== "" &&
          i.checkInYear !== "" &&
          i.checkOutMonth !== "" &&
          i.checkOutDay !== "" &&
          i.checkOutYear !== "" &&
          i.numberOfNights.includes("7")
      );

      formattedData.forEach((i) => {
        // maxOccupancy (keep @ top to avoid error)
        if (i.bedrooms.includes("sleeps")) {
          i.maxOccupancy = i.bedrooms.split("sleeps ")[1].split(")")[0];
          i.maxOccupancy = parseInt(i.maxOccupancy);
        }
        // maxOccupancy (keep @ top to avoid error)

        // archived
        i.archived = false;
        // archived

        // bedrooms
        let bedroomsTempVal = 0;
        i.bedroomsDisplay = "";
        i.bedrooms = i.bedrooms.toLowerCase();

        if (i.bedrooms.includes("hotel")) i.bedroomsDisplay = "hotel";

        if (i.bedrooms.includes("studio")) i.bedroomsDisplay = "studio";

        if (i.bedrooms.includes("bedroom")) {
          bedroomsTempVal = parseInt(i.bedrooms.split(" bedroom")[0]);
          i.bedroomsDisplay = i.bedrooms.split(" bedroom")[0];
        }
        i.bedrooms = bedroomsTempVal;
        // bedrooms

        // checkInDate
        i.checkInDate =
          i.checkInYear + "-" + i.checkInMonth + "-" + i.checkInDay;
        i.checkInDate = new Date(i.checkInDate).toISOString();
        i.checkInDate = moment(i.checkInDate).toISOString().substring(0, 10);
        // checkInDate

        // checkOutDate
        i.checkOutDate =
          i.checkOutYear + "-" + i.checkOutMonth + "-" + i.checkOutDay;
        i.checkOutDate = new Date(i.checkOutDate).toISOString();
        i.checkOutDate = moment(i.checkOutDate).toISOString().substring(0, 10);
        // checkOutDate

        // dates
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        // dates

        // destination
        // i.destination = i.destination.split("-")[0].trim().toLowerCase();
        // destination

        // id
        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        // id

        // kitchen
        i.kitchen = i.kitchen.toLowerCase();
        if (i.kitchen.includes("kitchen")) {
          i.kitchen = i.kitchen.split(" kitchen")[0].trim();
        }
        // kitchen

        // nightlyRate
        // if (i.nightlyRate.includes("$")) {
        //   i.nightlyRate = i.nightlyRate
        //     .split("($")[1]
        //     .split("/night")[0]
        //     .trim();
        //   i.nightlyRate = parseInt(i.nightlyRate);
        // }
        // nightlyRate

        // numberOfNights
        i.numberOfNights = i.numberOfNights.split("(")[1].split(" N")[0].trim();
        i.numberOfNights = parseInt(i.numberOfNights);
        // numberOfNights

        //resortAddress
        i.resortAddress = i.resortAddress.replace("Address:", "").trim();
        //resortAddress

        // resortPropertyId
        if (i.resortPropertyId == "0193") i.destination = "Lake Tahoe";
        // resortPropertyId

        // roomCost
        if (i.totalCost.includes("<span")) {
          i.totalCost = i.totalCost
            .split("<span")[0]
            .replace(" ", "")
            .replace("$", "")
            .trim();
        }
        i.roomCost = parseInt(i.totalCost);
        // roomCost

        // source
        i.source = "tripbeat";
        // source

        // object clean up
        delete i["web-scraper-order"];
        delete i["web-scraper-start-url"];
        delete i.checkInDay;
        delete i.checkInMonth;
        delete i.checkInYear;
        delete i.checkOutDay;
        delete i.checkOutMonth;
        delete i.checkOutYear;
        delete i.totalCost;
        delete i.nightlyRate;
        // object clean up
      });

      // this.$store.dispatch("setLoading", false);
      // return;

      // sorting data into unique property IDs
      let uniquePropertyIds = Array.from(
        new Set(formattedData.map((a) => a.resortPropertyId))
      ).map((resortPropertyId) => {
        return formattedData.find(
          (a) => a.resortPropertyId === resortPropertyId
        );
      });

      uniquePropertyIds = uniquePropertyIds.map((i) => i.resortPropertyId);

      for (let index = 0; index < uniquePropertyIds.length; index++) {
        const element = uniquePropertyIds[index];

        let collection = formattedData.filter(
          (item) => item.resortPropertyId == element
        );

        let firestoreData = {
          template: "inventoryListings",
          id: collection[0].resortPropertyId,
          destination: collection[0].destination,
          resortName: collection[0].resortName,
          items: collection,
          dateSubmitted: new Date().toISOString().substring(0, 10),
          source: "tripbeat",
        };

        let docRef = portalsApp
          .firestore()
          .collection("inventoryListings")
          .doc(firestoreData.id);

        await docRef.set(firestoreData);
      }

      // update scraping job in firestore
      await portalsApp
        .firestore()
        .collection("invScrapeJobs")
        .doc(scrapeID)
        .update({
          dataUploadedToDB: true,
        });

      // get web scraper account info
      let webScraperAccountInfo = await axios.get(
        "/api/get-web-scraper-account-info"
      );
      this.webScraperAccountInfo = webScraperAccountInfo.data.data.data;

      await this.$store.dispatch("setLoading", false);
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "success",
        text: "Inventory now live",
        timeout: 5000,
      });
    },
    async onStartNewScrape() {
      await this.$store.dispatch("setLoading", true);
      let updateSitemapDates = await axios.get("/api/update-sitemap-dates");
      if (updateSitemapDates.data.message == "success") {
        let propertiesRequest = await axios.post(
          "/api/start-new-inventory-properties-scrape"
        );
        let listingsRequest = await axios.post(
          "/api/start-new-inventory-listings-scrape"
        );
        if (
          propertiesRequest.data.message == "success" &&
          listingsRequest.data.message == "success"
        ) {
          this.newScrapeDialog = false;
          await this.$store.dispatch("setLoading", false);
          await this.$store.dispatch("setSnackbar", {
            display: true,
            color: "success",
            text: "Scrapes started",
            timeout: 5000,
          });
        }
      }
    },
    trimAll(item) {
      if (item)
        return item
          .replace(/(?! )\s/g, "")
          .replace(/[\t ]+\</g, "<")
          .replace(/&nbsp;/gi, "")
          .replace(/ +(?= )/g, "");
      return "";
    },
  },
};
</script>